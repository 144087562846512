import React, { useEffect, useState } from 'react';
import { Result, Button, Spin } from 'antd';
import qs from 'qs';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { captureException } from '@sentry/react';

import { Post } from '../../utils/request';
import { paypalAuthorizeRul } from 'utils/constants';

function ConnectPaypal(props) {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(undefined);

  useEffect(() => {
    try {
      const searchParams =
        location.search && qs.parse(location.search.slice(1));
      if (!(searchParams && searchParams.code)) {
        throw Error('paypal code is required');
      }

      const storeId = window.localStorage.getItem('sid');
      const params = { code: searchParams.code };

      if (storeId) {
        Object.assign(params, { store_id: storeId });
      }

      // eslint-disable-next-line
      callService(params);
    } catch (error) {
      setResult('fail');
      setLoading(false);
      console.log('ConnectPaypal error:', error);
      captureException(error);
    }
  }, []);

  async function callService(data) {
    try {
      await Post('/auth/paypal/bind', { data });
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }

    setResult('ok');
    window.localStorage.removeItem('sid');
    props.dispatch(routerRedux.push('/settings?id=8'));
  }

  function handleTryAgain() {
    const storeId = window.localStorage.getItem('sid');
    if (!storeId) {
      window.location.href = '/app/settings?id=8';
    } else {
      window.location.href = paypalAuthorizeRul;
    }
  }

  if (loading) {
    return (
      <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
    );
  }

  return (
    <div style={{ paddingTop: 150 }}>
      {result === 'fail' ? (
        <Result
          status="warning"
          title="Something went wrong with the PayPal authorization. Please try again."
          extra={
            <Button type="primary" key="console" onClick={handleTryAgain}>
              Try again
            </Button>
          }
        />
      ) : null}
      {result === 'ok' ? (
        <Result
          status="success"
          title="PayPal authorization successful!"
          extra={
            <Button type="primary" key="console" href="/app/settings?id=8">
              Go back to DSers
            </Button>
          }
        />
      ) : null}
    </div>
  );
}

export default connect(null)(ConnectPaypal);
